"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrayToRecord = exports.arrayToMap = void 0;
exports.partition = partition;
function partition(array, predicate) {
    const unmatched = [];
    const matched = [];
    for (const item of array) {
        if (predicate(item)) {
            matched.push(item);
        }
        else {
            unmatched.push(item);
        }
    }
    return [matched, unmatched];
}
/**
 * Extract the value at `key` from each element in `arr` and use `item[key]` as
 * the key in a `Map` key value store. Does nothing about collisions, so assumes
 * the values of `item[key]` are unique, if they are not unique the last item in
 * `arr` with the `item[key]` value "wins".
 * @see {@link arrayToRecord} for a version that returns a plain object.
 */
const arrayToMap = (arr, key) => {
    const output = new Map();
    return arr.reduce((memo, x) => {
        const k = x[key];
        memo.set(k, x);
        return memo;
    }, output);
};
exports.arrayToMap = arrayToMap;
/**
 * Extract the value at `key` from each element in `arr` and use `item[key]` as
 * the key in a key value object store. Does nothing about collisions, so
 * assumes the values of `item[key]` are unique, if they are not unique the last
 * item in `arr` with the `item[key]` value "wins".
 * @see {@link arrayToMap} for a version that returns a `Map`.
 */
const arrayToRecord = (arr, key) => {
    const data = (0, exports.arrayToMap)(arr, key);
    return Object.fromEntries(data);
};
exports.arrayToRecord = arrayToRecord;
