"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNamespaced = isNamespaced;
/**
 * Checks if the given `kind` is a namespaced string where "namespaced" means
 * has two or more parts separated by a colon and each part is at least one
 * character.
 * @param kind to be tested
 * @returns `true` if `kind` is a namespaced string.
 */
function isNamespaced(kind) {
    const parts = kind.split(':');
    return parts.length >= 2 && parts.every((part) => part.length >= 1);
}
