"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDate = isDate;
// Copied and modified from @sinclair/typebox/format
// See https://github.com/sinclairzx81/typebox/tree/83e05bb43ca79cb22c9efc27e2e16caf7cb6a0e5/example/formats
const DAYS = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const DATE = /^(\d\d\d\d)-(\d\d)-(\d\d)$/;
function IsLeapYear(year) {
    return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
}
/**
 * `[ajv-formats]` ISO8601 Date component
 * @example `2020-12-12`
 */
function isDate(value) {
    const matches = DATE.exec(value);
    if (!matches)
        return false;
    const year = +(matches[1] ?? '');
    const month = +(matches[2] ?? '');
    const day = +(matches[3] ?? '');
    return (month >= 1 &&
        month <= 12 &&
        day >= 1 &&
        day <= (month === 2 && IsLeapYear(year) ? 29 : DAYS[month] ?? 0));
}
