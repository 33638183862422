"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDateTime = isDateTime;
// Copied and modified from @sinclair/typebox/format
// See https://github.com/sinclairzx81/typebox/tree/83e05bb43ca79cb22c9efc27e2e16caf7cb6a0e5/example/formats
const date_1 = require("./date");
const time_1 = require("./time");
const DATE_TIME_SEPARATOR = /t|\s/i;
/**
 * `[ajv-formats]` ISO8601 DateTime
 * @example `2020-12-12T20:20:40+00:00`
 */
function isDateTime(value, strictTimeZone) {
    const [date, time] = value.split(DATE_TIME_SEPARATOR);
    return (typeof date === 'string' &&
        typeof time === 'string' &&
        (0, date_1.isDate)(date) &&
        (0, time_1.isTime)(time, strictTimeZone));
}
