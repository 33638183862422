"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTime = isTime;
// Copied and modified from @sinclair/typebox/format
// See https://github.com/sinclairzx81/typebox/tree/83e05bb43ca79cb22c9efc27e2e16caf7cb6a0e5/example/formats
const TIME = /^(\d\d):(\d\d):(\d\d(?:\.\d+)?)(z|([+-])(\d\d)(?::?(\d\d))?)?$/i;
/**
 * `[ajv-formats]` ISO8601 Time component
 * @example `20:20:39+00:00`
 */
function isTime(value, strictTimeZone) {
    const matches = TIME.exec(value) ?? [];
    if (!matches)
        return false;
    const hr = +(matches[1] ?? '');
    const min = +(matches[2] ?? '');
    const sec = +(matches[3] ?? '');
    const tz = matches[4];
    const tzSign = matches[5] === '-' ? -1 : 1;
    const tzH = +(matches[6] || 0);
    const tzM = +(matches[7] || 0);
    if (tzH > 23 || tzM > 59 || (strictTimeZone && !tz))
        return false;
    if (hr <= 23 && min <= 59 && sec < 60)
        return true;
    // leap second
    const utcMin = min - tzM * tzSign;
    const utcHr = hr - tzH * tzSign - (utcMin < 0 ? 1 : 0);
    return ((utcHr === 23 || utcHr === -1) &&
        (utcMin === 59 || utcMin === -1) &&
        sec < 61);
}
