export {
  AnimationInstructions,
  PublishesTo,
  OptionalString,
  SingleUnion,
  StringEnum,
  SubscribesTo,
  clamp,
  map,
  ModuleRenderInstructions,
  normalizePathname,
  NumberOrStringNumber,
  lerp,
  localVariable,
  randomInt,
  createComponentAdmin,
  createInstructionValidator,
  createInstructionFlowNodes,
  createSchemaValidator,
  isAboutMe,
  isAboutMeCore,
  isInstruction,
  getFlowStorageKey,
  getMousePos,
  extractModuleIdentifiers,
  dummyModuleIdentifiers,
  defaultOrientation,
  isOrientation,
  orientationAttr,
  storage,
  styleAttr,
  styleAttrRef,
  styleAttrUi,
  useAnimationStates,
  scrollToDescription,
  scrollTo,
  parseNumbericParameter,
  PRIVATE_FIELD_DATA,
} from './helpers';
export type {
  BroadcastFn,
  DeriveInstructionType,
  ModuleIdentifiers,
  Orientation,
  Oriented,
  SchemaInstructionsHelper,
  SchemaTypeHelper,
  TypeBoxSchema,
  MousePosition,
} from './helpers';
export {ModuleCollapse} from './components';
export {
  FALLBACK_PAGE_PATH,
  ShowInstructionsContext,
  ShowInstructionsProvider,
  dummyShowInstructionsProviderProps,
  useShowId,
  useDomainName,
  useShowInstructions,
  useExternalScript,
  useLocale,
  useIntersectionObserver,
  useManagedRef,
  useParsedText,
  useRenderedChildren,
  useSiteVersionId,
} from './hooks';
export type {AppPage, FetchInstructionsFn, Render} from './hooks';
export type {
  ComponentAdmin,
  ComponentConfig,
  ContentModule,
  DynamicComponent,
  DynamicComponentDictionary,
  GuestAuthChangeEvent,
  GuestAuthChangeEventName,
  GuestAuthLogoutEvent,
  GuestAuthLogoutEventName,
  GuestAuthSuccessEvent,
  GuestAuthSuccessEventName,
  GuestExternalAuthSuccessEventName,
  JSONArray,
  JSONObject,
  JSONValue,
  LayoutModule,
  ModuleCategory,
  ModuleForPreview,
  ModuleNode,
  ModuleProperties,
  RenameKey,
  SlotPropsLite,
  TypedComponentAdmin,
  WithHtmlId,
} from './types';
export {NextModule, Registry, RegistryError, RenderError} from './registry';
export type {Module} from './registry';
export type {Event, NamespacedEvent} from './state-machine';
export {isJSONObject, isJSONValue, moduleCategories} from './types';
export type {
  Align,
  JustifyPreset,
  FlexWrap,
  BackgroundAttachment,
  BackgroundRepeat,
  BackgroundPosition,
  BackgroundSize,
} from './schemas';
export {
  GlobalInstructionSchema,
  GuestInstructionSchema,
  GuestStorageInstructionSchema,
  backgroundSchema,
  backgroundConditionalSchema,
  borderSchema,
  layoutSchema,
  layoutSchemaAdvanced,
  opacitySchema,
  positionSchema,
  sizingSchema,
  spacingSchema,
  typographySchema,
} from './schemas';

import {GuestAuthLogoutEvent, GuestAuthLogoutEventName} from './types';

declare global {
  // Extends `#addEventListener` and `#removeEventListener` type definition to
  // include an overload for the custom events dispatched in relation to guest
  // authentication. These are copied from the module implementations so
  // consumers of the `@backstage-components/base` package will also have
  // access to them.
  interface HTMLElement {
    addEventListener(
      type: GuestAuthLogoutEventName,
      listener: (this: HTMLElement, ev: GuestAuthLogoutEvent) => void,
      options?: boolean | AddEventListenerOptions
    ): void;
    removeEventListener(
      type: GuestAuthLogoutEventName,
      listener: (this: HTMLElement, ev: GuestAuthLogoutEvent) => void,
      options?: boolean | EventListenerOptions
    ): void;
  }
}
