import {
  extractInstruction,
  reduceVariableInstructions,
  type ApiInstruction,
  type Instruction,
} from '@backstage/instructions';
import {type MutableRefObject} from 'react';
import {EMPTY, Observable, map, type Subject} from 'rxjs';
import {
  assign,
  fromEventObservable,
  fromPromise,
  raise,
  setup,
  type DoneActorEvent,
  type ErrorActorEvent,
} from 'xstate';
import {type BroadcastFunction} from './transformations/node.types';
import type {
  FetchInstructionsFn,
  ShowFetchInstructionsFn,
  ShowInstructionRequestType,
} from './types';

type TransitionEvent<
  Kind extends string,
  Data extends Record<string, unknown> = Record<string, never>,
> = Data extends Record<string, never> ? {type: Kind} : {type: Kind} & Data;

interface InstructionBaseContext {
  /** Ref for function used when retrieving instructions */
  getInstructions: MutableRefObject<ShowFetchInstructionsFn>;
  /** Subject into which newly retrieved instructions are pushed */
  subject: Subject<Instruction[] | Error>;
  /** Function called push instructions into an analytics batch */
  track: BroadcastFunction;
}

interface InstructionTypestateContext {
  observable?: Observable<ApiInstruction[]>;
  sinceInstructionId?: string;
  error?: Error;
  lastInstructions?: ApiInstruction[];
  hasInitialized: boolean;
}

type InstructionContext = InstructionBaseContext & InstructionTypestateContext;

type Action =
  | TransitionEvent<'INIT'>
  | TransitionEvent<'FETCH'>
  | TransitionEvent<'AWAIT_MORE'>
  | TransitionEvent<'PAUSE'>
  | TransitionEvent<'RESUME'>
  | TransitionEvent<'RECEIVE', {instructions: ApiInstruction[]}>
  | TransitionEvent<'REQUEST_ERROR', {error: Error}>
  | TransitionEvent<
      'REQUEST_COMPLETE',
      {instructions: ApiInstruction[]; latestInstructionId?: string}
    >
  | TransitionEvent<
      'SUBSCRIBE',
      {observable: Observable<ApiInstruction[]>; latestInstructionId?: string}
    >;

type FetchInstructionsResponse = Awaited<ReturnType<FetchInstructionsFn>>;

/** Promise creator called when transitioning into the `PENDING` state */
const pendingInvoke = (
  context: InstructionContext & {requestType: ShowInstructionRequestType}
): Promise<FetchInstructionsResponse> => {
  const getInstructions = context.getInstructions.current;
  if (typeof getInstructions === 'function') {
    return getInstructions(context.requestType, context.sinceInstructionId);
  } else {
    return Promise.resolve({});
  }
};

/** Actions to perform when `pendingInvoke` resolves */
const onDoneActions = [
  raise<
    InstructionContext,
    DoneActorEvent<FetchInstructionsResponse>,
    Action,
    undefined
  >(({event}) => {
    const result = event.output;
    if ('observable' in result) {
      return {
        type: 'SUBSCRIBE',
        observable: result.observable,
        latestInstructionId: result.latestInstructionId ?? undefined,
      };
    } else if (
      typeof result.data?.environmentById !== 'undefined' &&
      result.data?.environmentById !== null
    ) {
      return {
        type: 'REQUEST_COMPLETE',
        instructions: result.data.environmentById.showInstructions,
        latestInstructionId:
          result.data.environmentById.latestInstructionId ?? undefined,
      };
    } else if (typeof result.error !== 'undefined') {
      return {type: 'REQUEST_ERROR', error: result.error};
    } else {
      return {type: 'REQUEST_COMPLETE', instructions: []};
    }
  }),
];

/** Actions to perform when `pendingInvoke` rejects */
const onErrorActions = [
  raise<InstructionContext, ErrorActorEvent<unknown>, Action, undefined>(
    ({event}) => {
      const reason: unknown = event.error;
      return {
        type: 'REQUEST_ERROR',
        error: reason instanceof Error ? reason : new Error(),
      };
    }
  ),
];

const sinceInstructionIdRealTime = ({
  context,
  event,
}: {context: InstructionContext; event: Action}) => {
  const lastInstruction =
    'instructions' in event ? event.instructions.slice(-1)[0] : undefined;

  return typeof lastInstruction !== 'undefined'
    ? lastInstruction.id
    : context.sinceInstructionId;
};

const sinceInstructionIdPoll = ({
  context,
  event,
}: {context: InstructionContext; event: Action}) => {
  // `latestInstructionId` should always exist when polling since it's from GQL.
  if ('latestInstructionId' in event) {
    return event.latestInstructionId;
  }
  return sinceInstructionIdRealTime({context, event});
};
const POLL_INTERVAL = 5000;
/**
 * A state machine which manages the retrieval of new instructions. Retrieved
 * instructions are passed into the given `Subject` when returned by the given
 * `getInstructions` function. `getInstructions` is invoked when the state
 * machine enters the `PENDING` state.
 */
export const showInstructionsMachine = setup({
  types: {} as {
    context: InstructionContext;
    events: Action;
    input: InstructionBaseContext;
  },
  actors: {
    fetchInstructions: fromPromise<
      FetchInstructionsResponse,
      Parameters<typeof pendingInvoke>[0]
    >(async ({input}) => pendingInvoke(input)),
    listenInstructions: fromEventObservable<
      Action,
      Observable<ApiInstruction[]>
    >(({input}) =>
      input.pipe(map((instructions) => ({type: 'RECEIVE', instructions})))
    ),
  },
  actions: {
    pushInstructions: ({context}) => {
      if (Array.isArray(context.lastInstructions)) {
        applyInstructions(context, context.lastInstructions);
      }
    },
  },
}).createMachine({
  predictableActionArguments: true,
  id: 'instructions',
  initial: 'INITIALIZING',
  context: ({input}): InstructionContext => ({
    getInstructions: input.getInstructions,
    subject: input.subject,
    track: input.track,
    hasInitialized: false,
  }),
  states: {
    INITIALIZING: {
      on: {INIT: {target: 'WAITING'}},
    },
    WAITING: {
      on: {
        FETCH: {target: 'PENDING'},
        PAUSE: {target: 'PAUSED'},
      },
    },
    PENDING: {
      invoke: {
        id: 'fetch',
        src: 'fetchInstructions',
        input: ({context, event}) => ({
          ...context,
          // If resuming from a paused state, we want to fetch the latest Show
          // state instructions, without any action instructions (CATCHUP).
          // For instance, we don't want a salvo of no-longer-relevant
          // confetti-firing instructions when the user returns to the page.
          // Polling is close to real-time, so we do want the action
          // instructions also (POLL).
          requestType:
            event.type === 'RESUME' || !context.hasInitialized
              ? 'CATCHUP'
              : 'POLL',
        }),
        onDone: {
          actions: [assign({hasInitialized: true}), ...onDoneActions],
        },
        onError: {actions: onErrorActions},
      },
      on: {
        PAUSE: {target: 'PAUSED'},
        REQUEST_ERROR: {
          actions: assign({error: ({event}) => event.error}),
          target: 'ERROR',
        },
        REQUEST_COMPLETE: {
          actions: [
            assign({
              lastInstructions: ({event}) => {
                return event.instructions.slice(0);
              },
              sinceInstructionId: sinceInstructionIdPoll,
            }),
          ],
          target: 'DONE',
        },
        SUBSCRIBE: {
          actions: assign({
            observable: ({event}) => event.observable,
            sinceInstructionId: sinceInstructionIdRealTime,
          }),
          target: 'LISTENING',
        },
      },
    },
    LISTENING: {
      invoke: {
        input: ({event}) =>
          event.type === 'SUBSCRIBE' ? event.observable : EMPTY,
        src: 'listenInstructions',
        // Go to `WAITING` when the observable completes so `getInstructions`
        // is triggered again quickly. Going to `DONE` waits 5 seconds before
        // re-fetching.
        onDone: {target: 'WAITING'},
        onError: {target: 'ERROR'},
      },
      on: {
        PAUSE: {target: 'PAUSED'},
        RECEIVE: {
          actions: [
            assign({
              lastInstructions: ({event}) => {
                return event.instructions.slice(0);
              },
              sinceInstructionId: sinceInstructionIdRealTime,
            }),
            ({context, event}) => {
              applyInstructions(context, event.instructions);
            },
          ],
        },
      },
    },
    ERROR: {
      entry: ({context}) => {
        if (context.error instanceof Error) {
          context.subject.next(context.error);
        }
      },
      on: {
        AWAIT_MORE: {target: 'WAITING'},
        PAUSE: {target: 'PAUSED'},
      },
      after: {
        [POLL_INTERVAL]: {
          actions: raise({type: 'AWAIT_MORE'}),
        },
      },
    },
    DONE: {
      entry: ['pushInstructions'],
      on: {
        AWAIT_MORE: {target: 'WAITING'},
        PAUSE: {target: 'PAUSED'},
      },
      after: {
        [POLL_INTERVAL]: {
          actions: raise({type: 'AWAIT_MORE'}),
        },
      },
    },
    PAUSED: {
      on: {
        RESUME: {target: 'PENDING'},
      },
    },
  },
});

/**
 * For each `ApiInstruction` received push it into the `Subject` and track it
 * via the analytics `BroadcastFunction` as long as the `sinceInstructionId` is
 * set indicating this is not the initial payload.
 */
function applyInstructions(
  context: InstructionContext,
  apiInstructions: ApiInstruction[]
): void {
  const {actions, setters} = reduceVariableInstructions(apiInstructions);
  const instructions = setters.concat(actions).map(extractInstruction);
  context.subject.next(instructions);
  // `sinceInstructionId` is always set after the first payload is received,
  // this avoids tracking the initial payload
  if (typeof context.sinceInstructionId !== 'undefined') {
    instructions.forEach((instruction) => context.track(instruction, 'api'));
  }
}
