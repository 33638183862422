import type {Static} from '@sinclair/typebox';
import {StringEnum} from './typebox-helpers';

export const orientationAttr = StringEnum(
  ['horizontal', 'vertical', 'horizontal-reverse', 'vertical-reverse'] as const,
  {
    title: 'Orientation',
    description:
      'The direction in which the items are arranged within this module. "Horizontal" means items are placed side by side, while "Vertical" means they are stacked on top of each other. "horizontal-reverse" and "vertical-reverse" flip the order of items horizontally and vertically, respectively.',
    default: 'vertical',
  }
);

export type Orientation = Static<typeof orientationAttr>;

const orientationPattern =
  /^(vertical|horizontal|horizontal-reverse|vertical-reverse)$/;

export const defaultOrientation: Orientation = 'vertical';

export const isOrientation = (o: unknown): o is Orientation => {
  return typeof o === 'string' ? orientationPattern.test(o) : false;
};

export type Oriented = {
  orientation: Orientation;
};
