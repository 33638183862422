import {Type} from '@sinclair/typebox';
import {OptionalString, orientationAttr, StringEnum} from '../helpers';

const justifyContentOptions = [
  'start',
  'center',
  'end',
  'space-between',
  'space-around',
  'space-evenly',
  '',
] as const;
export type JustifyPreset = (typeof justifyContentOptions)[number];

const alignItemsOptions = [
  'start',
  'center',
  'end',
  'stretch',
  'baseline',
  '',
] as const;
export type Align = (typeof alignItemsOptions)[number];

const advancedAlignOptions = [
  'auto',
  'flex-start',
  'flex-end',
  'center',
  'baseline',
  'stretch',
  '',
] as const;
export type AdvancedAlign = (typeof advancedAlignOptions)[number];

const flexWrapOptions = ['nowrap', 'wrap', 'wrap-reverse', ''] as const;
export type FlexWrap = (typeof flexWrapOptions)[number];

export const layoutSchema = Type.Object({
  orientation: orientationAttr,
  justifyPreset: Type.Optional(
    StringEnum(justifyContentOptions, {
      title: 'Justify Content',
      description: 'Defines how items are aligned along the main axis.',
    })
  ),
  align: Type.Optional(
    StringEnum(alignItemsOptions, {
      title: 'Align Items',
      description: 'Defines how items are aligned along the cross axis.',
    })
  ),
  gap: OptionalString({
    title: 'Gap Sizing',
    description: 'Set up the row and column gaps around your stack.',
  }),
});

export const layoutSchemaAdvanced = Type.Object({
  alignSelf: Type.Optional(
    StringEnum(advancedAlignOptions, {
      title: 'Align Self',
      description:
        'Allows individual items to align differently along the cross axis.',
    })
  ),
  flexWrap: Type.Optional(
    StringEnum(flexWrapOptions, {
      title: 'Wrap',
      description:
        'Defines if the flex items should wrap or stay on a single line.',
    })
  ),
  order: Type.Optional(
    Type.Number({
      title: 'Order',
      description: 'Controls the order in which the flex/grid items appear.',
      minimum: 0,
    })
  ),
  flexGrow: Type.Optional(
    Type.Number({
      title: 'Grow',
      description:
        'Specifies how much the flex item will grow relative to the rest.',
      minimum: 0,
    })
  ),
  flexShrink: Type.Optional(
    Type.Number({
      title: 'Shrink',
      description:
        'Specifies how much the flex item will shrink relative to the rest.',
      minimum: 0,
    })
  ),
  flexBasis: OptionalString({
    title: 'Basis',
    description:
      'Defines the default size of an element before the remaining space is distributed.',
  }),
});

export const spacingSchema = Type.Object({
  margin: OptionalString({
    title: 'Margin',
    description:
      'The space outside the edges of your content. It adds space between your content and other elements.',
  }),
  padding: OptionalString({
    title: 'Padding',
    description:
      'The space inside the edges of your content. It adds space between the content and the edge of its container.',
  }),
});

const backgroundAttachmentOptions = [
  'scroll', // CSS default
  'fixed',
  'local',
  '',
] as const;
export type BackgroundAttachment = (typeof backgroundAttachmentOptions)[number];

const backgroundRepeatOptions = [
  'repeat', // CSS default
  'repeat-x',
  'repeat-y',
  'no-repeat',
  'space',
  'round',
  '',
] as const;
export type BackgroundRepeat = (typeof backgroundRepeatOptions)[number];

const backgroundPositionOptions = [
  'left top', // Equivalent to CSS default (0% 0%)
  'left center',
  'left bottom',
  'right top',
  'right center',
  'right bottom',
  'center top',
  'center center',
  'center bottom',
  '',
] as const;
export type BackgroundPosition = (typeof backgroundPositionOptions)[number];

const backgroundSizeOptions = [
  'auto', // CSS default
  'cover',
  'contain',
  '',
] as const;
export type BackgroundSize = (typeof backgroundSizeOptions)[number];

export const backgroundConditionalSchema = Type.Object({
  backgroundAttachment: Type.Optional(
    StringEnum(backgroundAttachmentOptions, {
      title: 'Background Attachment',
      description:
        'Defines whether the background image scrolls with the page or is fixed.',
      default: 'scroll',
    })
  ),
  backgroundRepeat: Type.Optional(
    StringEnum(backgroundRepeatOptions, {
      title: 'Background Repeat',
      description: 'Determines how the background image is repeated.',
      default: 'no-repeat',
    })
  ),
  backgroundPosition: Type.Optional(
    StringEnum(backgroundPositionOptions, {
      title: 'Background Position',
      description:
        'Specifies the position of the background image within the element.',
      default: 'center center',
    })
  ),
  backgroundSize: Type.Optional(
    StringEnum(backgroundSizeOptions, {
      title: 'Background Size',
      description: 'Controls the size of the background image.',
      default: 'cover',
    })
  ),
});

export const backgroundSchema = Type.Object(
  {
    backgroundColor: Type.Optional(
      OptionalString({
        title: 'Background Color',
        description:
          'Sets the background color of the element. The default is "transparent."',
        default: 'transparent',
      })
    ),
    backgroundImage: Type.Optional(
      OptionalString({
        title: 'Background Image',
        description:
          'Specifies an image to be used as the background for the element. The default is "none," meaning no background image is used.',
        default: 'none',
      })
    ),
  },
  {
    dependencies: {
      backgroundImage: {
        oneOf: [
          {
            properties: {
              backgroundImage: {enum: ['']}, // No backgroundImage selected
            },
          },
          {
            properties: {
              backgroundImage: {not: {enum: ['']}}, // backgroundImage is selected
              ...backgroundConditionalSchema.properties,
            },
          },
        ],
      },
    },
  }
);

export const sizingSchema = Type.Object({
  width: OptionalString({title: 'Width'}),
  height: OptionalString({title: 'Height'}),
  minWidth: OptionalString({title: 'Min Width'}),
  maxWidth: OptionalString({title: 'Max Width'}),
  minHeight: OptionalString({title: 'Min Height'}),
  maxHeight: OptionalString({title: 'Max Height'}),
});

export const borderSchema = Type.Object({
  borderColor: OptionalString('Border Color'),
  borderRadius: OptionalString('Border Radius'),
  borderWidth: OptionalString('Border Width'),
  borderStyle: OptionalString('Border Style'),
});

export const typographySchema = Type.Object({
  textColor: OptionalString('Text Color'),
  fontSize: OptionalString('Font Size'),
  fontFamily: OptionalString('Font Family'),
});

export const positionSchema = Type.Object({}, {title: 'Position'});

export const opacitySchema = Type.Object({
  opacity: Type.Number({
    title: 'Opacity',
    default: 1,
    minimum: 0,
    maximum: 1,
    multipleOf: 0.001,
  }),
});
