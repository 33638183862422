"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reduceVariableInstructions = reduceVariableInstructions;
const array_helpers_1 = require("@backstage/utils/array-helpers");
/**
 * Since multiple variables can be set (or unset) at once and multiple
 * `Global:variable:(un)set` (or `variable:(un)set`) instructions can be in an
 * instruction batch instruction batches are pre-processed to aggregate variable
 * manipulation instructions into a single instruction and then provided
 * separately from other instructions.
 */
function reduceVariableInstructions(instructions) {
    const varSet = {
        global: { toSet: new Map(), toUnset: new Set() },
        local: { toSet: new Map(), toUnset: new Set() },
    };
    const [variables, actions] = (0, array_helpers_1.partition)(instructions, (instruction) => instruction.kind === 'Global:variable:set' ||
        instruction.kind === 'Global:variable:unset' ||
        instruction.kind === 'variable:set' ||
        instruction.kind === 'variable:unset');
    // reduce the setting instructions a global and local 'on-set' and 'on-unset'
    // instructions
    for (const instruction of variables) {
        const meta = typeof instruction.meta === 'object' &&
            !Array.isArray(instruction.meta) &&
            instruction.meta !== null
            ? instruction.meta
            : {};
        if (instruction.kind === 'Global:variable:set') {
            Object.entries(meta).forEach((entry) => addVariable(varSet.global, entry));
        }
        else if (instruction.kind === 'Global:variable:unset') {
            Object.entries(meta).forEach((entry) => removeVariable(varSet.global, entry));
        }
        else if (instruction.kind === 'variable:set') {
            Object.entries(meta).forEach((entry) => addVariable(varSet.local, entry));
        }
        else if (instruction.kind === 'variable:unset') {
            Object.entries(meta).forEach((entry) => removeVariable(varSet.local, entry));
        }
    }
    const setters = [
        lastInstruction(variables, 'Global:variable:set', varSet.global.toSet),
        lastInstruction(variables, 'Global:variable:unset', varSet.global.toUnset),
        lastInstruction(variables, 'variable:set', varSet.local.toSet),
        lastInstruction(variables, 'variable:unset', varSet.local.toUnset),
    ].filter((instruction) => typeof instruction !== 'undefined');
    return { setters, actions };
}
/** Add the `entry` to the given `details` aggregator. */
function addVariable(details, entry) {
    const [key, value] = entry;
    details.toSet.set(key, value);
    details.toUnset.delete(key);
}
/** Remove the `entry` from the given `details` aggregator. */
function removeVariable(details, entry) {
    const [key] = entry;
    details.toSet.delete(key);
    details.toUnset.add(key);
}
/**
 * For a given instruction `type` and detail information generate a valid
 * `Instruction` object.
 */
function lastInstruction(instructions, kind, data) {
    const instruction = instructions.reduce((memo, current) => {
        if (current.kind === kind) {
            return current;
        }
        else {
            return memo;
        }
    }, undefined);
    if (data.size > 0 && typeof instruction !== 'undefined') {
        const entries = data instanceof Map
            ? data.entries()
            : Array.from(data.values()).map((v) => [v, null]);
        return { ...instruction, meta: Object.fromEntries(entries) };
    }
    else {
        return undefined;
    }
}
