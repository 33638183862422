import type {Instruction} from '@backstage/instructions';
import {type SiteDetails} from '@backstage/attendee-ui-types';
import type {TUnion} from '@sinclair/typebox';
import {useMachine} from '@xstate/react';
import {useObservableState, useSubscription} from 'observable-hooks';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  type FC,
  type MutableRefObject,
  type PropsWithChildren,
} from 'react';
import {filter, map, mergeMap, Observable, partition, Subject} from 'rxjs';
import {
  createInstructionValidator,
  FLOW_IGNORE,
  isAboutMe,
  storage,
  type Broadcaster,
  type DeriveInstruction,
  type InstructionSchema,
  type ModuleIdentifiers,
} from '../../helpers';
import {Registry} from '../../registry';
import {JSONValue, SiteVariableLookup} from '../../types';
import {useManagedRef} from '../useManagedRef';
import {useModuleIdentifiers} from '../useModuleIdentifiers';
import {useTrackInstruction} from '../useTrackInstruction';
import {CollectingBehaviorSubject} from './CollectingBehaviorSubject';
import {GlobalInstructionHandlingProvider} from './GlobalInstructionHandlingProvider';
import {processInstructionFlows} from './process-instruction-flows';
import {showInstructionsMachine} from './show-instructions-machine';
import {BroadcastFunction, LogFunction} from './transformations/node.types';
import type {
  AppPage,
  FetchInstructionsFn,
  ShowFetchInstructionsFn,
} from './types';
import {useShowState} from './useShowState';

export interface ShowInstructionsContextValue {
  /** Function to execute to get details about the current page */
  getCurrentPage: () => AppPage | undefined;
  /** Id of the show whose instructions are being handled. */
  showId: string;
  /** The domain name for the current site */
  domainName?: string;
  simpleBroadcast: BroadcastFunction;
  subject: Subject<Instruction[] | Error>;
  isDebugEnabled?: boolean;
}

/**
 * Explicit path used to indicate the page should be used for fallback (404)
 * content. If this ever changes check other places using this to make sure
 * validation related Regular Expressions are updated to match the new value.
 */
export const FALLBACK_PAGE_PATH = '/*';

const FALLBACK_APP_PAGE: AppPage | undefined =
  typeof document !== 'undefined'
    ? {
        pathname: FALLBACK_PAGE_PATH,
        structure: document.implementation.createDocument(null, 'Root', null),
      }
    : undefined;

export interface ShowInstructionsProviderProps {
  /** Origins in which the site may be embedded */
  allowedEmbedOrigins?: string[];
  /** URL to which instructions will be transmitted as analytics */
  analyticsEndpoint?: string;
  /** Token used to identify a specific guest anonymously */
  analyticsToken?: string;
  /** Contains structured page data for each path */
  appPages?: AppPage[];
  /**
   * Whether the context provider should automatically fetch instructions when
   * mounting. This option is intended to be used for tests, to avoid the "not
   * wrapped in act" warning
   * @default true
   */
  autoFetch?: boolean;
  /** Domain on which the `showId` was viewed */
  domainName?: string;
  /**
   * Function used to retrieve new instructions, performs no fetches if not
   * provided. This can be used to prevent retrieving instructions until the
   * `showId` can be confidently provided.
   */
  fetchInstructions?: FetchInstructionsFn;
  /**
   * If set to `true` will be treated as though the provider is loaded
   * regardless of internal state.
   */
  isLoaded?: boolean;
  /** Element at the root of the application */
  rootElement?: HTMLElement;
  /** Id of the show whose instructions are being handled. */
  showId: string;
  showControllerType: SiteDetails['showControllerType'];

  /** "dictionary" of SiteVariable keys to values */
  siteVariableLookup?: SiteVariableLookup;
  /** Whether the current site should enable debugging */
  debugEnabled?: boolean;
  /** Whether instruction fetching is paused */
  isInstructionFetchingPaused: boolean;
}

/**
 * Generates an object with "dummy" values for required
 * `ShowInstructionsProvider` props.
 * @private exported for testing.
 */
export const dummyShowInstructionsProviderProps =
  (): ShowInstructionsProviderProps => ({
    autoFetch: false,
    showId: 'UNUSED',
    fetchInstructions: async () => ({}),
    showControllerType: 'POLL',
    isInstructionFetchingPaused: false,
  });

/**
 * @private Exported for testing purposes only
 */
export const ShowInstructionsContext = createContext<
  ShowInstructionsContextValue | undefined
>(undefined);
ShowInstructionsContext.displayName = 'ShowInstructionsContext';

/**
 * `useContext` wrapper for `ShowInstructionsContext` - prevents misuse of the context and fails fast.
 */
const useShowInstructionsContext = (): ShowInstructionsContextValue => {
  const context = useContext(ShowInstructionsContext);
  if (context === undefined) {
    throw new Error(
      'useShowInstructionsContext must be used within a ShowInstructionsProvider'
    );
  }
  return context;
};

/**
 * The list of instruction types marked as "internal only" by the presence of
 * the `FLOW_IGNORE` property in the schema.
 */
const internalTopics: string[] = [];

/**
 * Context `Provider` to create and hold show instructions.
 */
export const ShowInstructionsProvider: FC<
  PropsWithChildren<ShowInstructionsProviderProps>
> = (props) => {
  const {
    allowedEmbedOrigins,
    analyticsEndpoint,
    analyticsToken,
    appPages = [],
    autoFetch = true,
    children,
    domainName,
    fetchInstructions,
    rootElement,
    showId,
    siteVariableLookup = {},
    debugEnabled,
    showControllerType,
    isInstructionFetchingPaused,
  } = props;
  const track = useTrackInstruction({
    analyticsEndpoint,
    analyticsToken,
    domainName,
    showId,
  });
  // Collects instructions which have been broadcast for delivery to modules
  const subjectRef = useRef(new CollectingBehaviorSubject<Instruction>());
  const appPagesRef = useManagedRef(appPages);
  const isDebugEnabled = debugEnabled || storage.getItem('debug') === 'true';
  const value = useMemo(
    () => ({showId, domainName, subject: subjectRef.current}),
    [showId, domainName]
  );
  const earlyInstructions = useRef<Instruction[]>([]);
  const isLoaded = useRef(props.isLoaded ?? false);
  // Set up broadcasting which will track every instruction at "broadcast"
  // passing along the `source` information as part of the tracking
  const simpleBroadcast: BroadcastFunction = useMemo(() => {
    return (instruction, source = 'internal') => {
      track(instruction, source);
      if (isLoaded.current || internalTopics.includes(instruction.type)) {
        subjectRef.current.next([instruction]);
      } else {
        earlyInstructions.current.push(instruction);
      }
    };
  }, [track]);
  // Set up the logging function used for debugging
  const log: LogFunction = useCallback(
    (icon, ...tail) => {
      if (isDebugEnabled || storage.getItem('logInstructions') === 'true') {
        console.log(`%c${icon}`, 'font-size: 24px;', ...tail);
      }
    },
    [isDebugEnabled]
  );
  // If debugging features are on then add a `broadcast` function to the window
  // This gives a way for developers to test out broadcasting an arbitrary
  // instruction in the browser
  useEffect(() => {
    if (
      storage.getItem('debug:broadcast') === 'true' &&
      typeof window !== 'undefined' &&
      window.location.hostname === 'localhost'
    ) {
      // @ts-expect-error adding to global
      window.broadcast = simpleBroadcast;
      return () => {
        // @ts-expect-error deleting from global
        delete window.broadcast;
      };
    }
  }, [simpleBroadcast]);

  // If instruction logging is turned on this subscription will log everything
  // coming through `subject`.
  useSubscription(value.subject, (instructions) => {
    // someone turned on logging or the debug flag is on
    if (isDebugEnabled) {
      console.debug('i', JSON.stringify(instructions));
    }
  });
  // This uses knowledge of another internal component to get the last navigated
  // pathname
  const appPagePath$ = useMemo(
    () =>
      value.subject.pipe(
        filter((value): value is Instruction[] => Array.isArray(value)),
        mergeMap((instructions) => instructions),
        filter((instruction) => instruction.type === 'Router:on-navigate'),
        map((instruction) =>
          instruction.meta.currentPath?.toString().toLowerCase()
        )
      ),
    [value.subject]
  );
  const appPagePath = useObservableState(appPagePath$, FALLBACK_PAGE_PATH);
  const getCurrentPage = useCallback(
    () =>
      extractPage(appPagesRef.current, appPagePath) ??
      extractPage(appPagesRef.current, FALLBACK_PAGE_PATH) ??
      FALLBACK_APP_PAGE,
    [appPagePath, appPagesRef]
  );

  // Include `appPages` so XML structure is printed if underlying structure of
  // XML documents associated with pages change
  // biome-ignore lint/correctness/useExhaustiveDependencies: see above
  useEffect(() => {
    if (typeof appPagePath === 'string' && isDebugEnabled) {
      const structure = getCurrentPage()?.structure;
      if ((structure?.querySelector('Root')?.childNodes.length ?? 0) > 0) {
        console.debug(
          '✨✨ xml structure for',
          appPagePath,
          getCurrentPage()?.structure
        );
      }
    }
  }, [appPagePath, appPages, isDebugEnabled, getCurrentPage]);

  // Create the subscription to process flows
  useSubscription(value.subject, (instructions) => {
    if (Array.isArray(instructions)) {
      processInstructionFlows({
        broadcast: simpleBroadcast,
        getCurrentPage,
        instructions,
        log,
        showId,
        domainName,
        siteVariableLookup,
      });
    }
  });

  // These are refs so that `showInstructionsMachine` doesn't get recreated
  // when either of these change. The value of `getInstructionsRef` will be
  // picked up the next time the state machine fetches.
  const showIdRef = useManagedRef(showId);
  const getInstructionsRef = useManagedRef<ShowFetchInstructionsFn>(
    (requestType, since) =>
      typeof fetchInstructions === 'function'
        ? fetchInstructions(showIdRef.current, requestType, since)
        : Promise.resolve({})
  );
  // Create the instructions polling state machine
  const [state, dispatch] = useMachine(showInstructionsMachine, {
    input: {
      getInstructions: getInstructionsRef,
      subject: value.subject,
      track,
    },
  });
  // Setup the instruction fetching function only if in the `INITIALIZING`
  // state, moving to the `WAITING` state to kick off the first fetch
  useEffect(() => {
    if (
      state.matches('INITIALIZING') &&
      typeof fetchInstructions === 'function'
    ) {
      dispatch({type: 'INIT'});
    }
  }, [dispatch, fetchInstructions, state]);
  // Only request instructions if `fetchInstructions` is provided in props,
  // waits until `getInstructions` exists before fetching first batch
  useEffect(() => {
    const currentPage = getCurrentPage();
    const isDone = state.matches('DONE') || state.matches('LISTENING');
    if (showControllerType === 'REAL_TIME' && state.matches('DONE')) {
      dispatch({type: 'AWAIT_MORE'});
    } else if (state.matches('WAITING') && autoFetch) {
      dispatch({type: 'FETCH'});
    } else if (currentPage && !isLoaded.current && isDone) {
      // The state machine moves to `DONE` (or `ERROR`) after the first fetch is
      // complete and so indicate the initial fetch of instructions has been
      // completed
      onLoad(isLoaded, earlyInstructions, value.subject);
    }
  }, [
    autoFetch,
    getCurrentPage,
    dispatch,
    state,
    value.subject,
    showControllerType,
  ]);
  // When forced into an `isLoaded` state send any collected instructions
  useEffect(() => {
    if (props.isLoaded) {
      onLoad(isLoaded, earlyInstructions, value.subject);
    }
  }, [props.isLoaded, value.subject]);
  const instructions = useMemo(() => {
    // Flatten the instructions so receivers do not get them in batches
    const [, instructions$] = partition(
      value.subject,
      (next): next is Error => next instanceof Error
    );
    const observable: Observable<Instruction> = instructions$.pipe(
      mergeMap((value) => {
        if (Array.isArray(value)) {
          return value;
        } else {
          return [value];
        }
      })
    );
    return observable;
  }, [value.subject]);
  const showStateRef = useShowState(instructions);
  // When the path changes reset collected instructions so they do not replay
  // on the new page and broadcast the current state
  useSubscription(appPagePath$, {
    next() {
      value.subject.reset();
      // If the global state is empty there is no reason to broadcast it
      if (Object.keys(showStateRef.current.global).length > 0) {
        // Push `Global:state:on-change` to the next tick
        setTimeout(() =>
          simpleBroadcast(
            {meta: showStateRef.current.global, type: 'Global:state:on-change'},
            'internal'
          )
        );
      }
    },
  });

  useEffect(() => {
    dispatch({type: isInstructionFetchingPaused ? 'PAUSE' : 'RESUME'});
  }, [dispatch, isInstructionFetchingPaused]);

  const contextValue = useMemo(() => {
    const result: ShowInstructionsContextValue = {
      getCurrentPage,
      simpleBroadcast,
      isDebugEnabled,
      ...value,
    };
    return result;
  }, [getCurrentPage, isDebugEnabled, simpleBroadcast, value]);
  return (
    <ShowInstructionsContext.Provider value={contextValue}>
      <GlobalInstructionHandlingProvider
        allowedEmbedOrigins={allowedEmbedOrigins}
        domainName={domainName}
        rootElement={rootElement}
      >
        {children}
      </GlobalInstructionHandlingProvider>
    </ShowInstructionsContext.Provider>
  );
};

/**
 * For a given list of `pages` extract the one where `pathname` matches the
 * given `path`.
 */
const extractPage = (pages: AppPage[], path?: JSONValue): AppPage | undefined =>
  pages.find(
    (page) => page.pathname.toLowerCase() === path?.toString().toLowerCase()
  );

/**
 * Set "loaded" state to `true` and push any collected instructions into
 * `subject` before clearing them.
 * @param isLoaded `Ref` for the "loaded" state
 * @param earlyInstructions `Ref` for the collected instructions
 * @param subject into which `earlyInstructions` will be pushed
 */
function onLoad(
  isLoaded: MutableRefObject<boolean>,
  earlyInstructions: MutableRefObject<Instruction[]>,
  subject: Subject<Error | Instruction[]>
): void {
  isLoaded.current = true;
  // Send any instructions that came in before first batch was loaded
  subject.next(earlyInstructions.current);
  // Clear out the early instructions; just in case
  earlyInstructions.current = [];
}

/**
 * Provides the `showId` for which instructions are currently being broadcast
 * and received.
 * @returns the `showId` of the current show instructions.
 */
export const useShowId = (): string => {
  const {showId} = useShowInstructionsContext();
  return showId;
};

/**
 * Provides the `domainName` for which instructions are currently being broadcast
 * and received, as stored in the `ShowInstructionsProvider`.
 * @returns the `domainName` of the current show instructions.
 */
export const useDomainName = (): string | undefined => {
  const {domainName} = useShowInstructionsContext();
  return domainName || undefined;
};

/**
 * Gives access to instruction messages for context's `showId` for instructions
 * which match the given `schema`.
 *
 * **WARN** Do not use this unless there are no `ModuleIdentifiers` for your
 * component.
 *
 * @param schema of the instructions to be broadcast and received
 * @returns object with show instructions observable and a function to
 * broadcast a new instruction.
 * @private exported for internal usage, using this API is considered undefined
 * behavior.
 */
export function useShowInstructions<
  InstructionTypes extends InstructionSchema[],
>(schema: TUnion<InstructionTypes>): ShowInstructions<InstructionTypes>;
/**
 * Gives access to instruction messages for context's `showId` for instructions
 * which match the given `schema`.
 * @param schema of the instructions to be broadcast and received
 * @param identifiers of the module broadcasting and receiving; if provided the
 * resulting `broadcast` function will always include it in the broadcasts.
 * @returns object with show instructions observable and a function to
 * broadcast a new instruction.
 */
export function useShowInstructions<
  InstructionTypes extends InstructionSchema[],
>(
  schema: TUnion<InstructionTypes>,
  identifiers: ModuleIdentifiers
): ShowInstructions<InstructionTypes>;

export function useShowInstructions<
  InstructionTypes extends InstructionSchema[],
>(
  schema: TUnion<InstructionTypes>,
  identifiers?: ModuleIdentifiers
): ShowInstructions<InstructionTypes> {
  type ValidatedInstruction = DeriveInstruction<InstructionTypes>;
  const isValidatedInstruction = useMemo(
    () => createInstructionValidator(schema),
    [schema]
  );
  const {getCurrentPage, simpleBroadcast, subject} =
    useShowInstructionsContext();
  const moduleIdentifiers = useModuleIdentifiers(identifiers);

  const about = useMemo(
    () => (moduleIdentifiers ? `#${moduleIdentifiers.id}` : null),
    [moduleIdentifiers]
  );

  // The `broadcast` function always adds `about` to the instruction, if given
  const broadcast: Broadcaster<InstructionTypes> = useCallback(
    (instruction) => {
      if (isValidatedInstruction(instruction)) {
        const instructionWithAbout = {
          type: instruction.type,
          meta: {
            ...instruction.meta,
            ...(typeof about === 'string' ? {about} : undefined),
          },
        };
        simpleBroadcast(instructionWithAbout, moduleIdentifiers);
      } else {
        console.warn({
          tag: 'useShowInstructions',
          msg: 'Invalid instruction',
          instruction,
        });
      }
    },
    [about, isValidatedInstruction, moduleIdentifiers, simpleBroadcast]
  );
  const [errors, instructions] = useMemo(() => {
    // Flatten the instructions so receivers do not get them in batches
    const [errors$, instructions$] = partition(
      subject,
      (next): next is Error => next instanceof Error
    );
    const observable: Observable<ValidatedInstruction> = instructions$.pipe(
      mergeMap((value) => {
        if (Array.isArray(value)) {
          return value;
        } else {
          return [value];
        }
      }),
      filter(isValidatedInstruction)
    );
    return [errors$, observable];
  }, [isValidatedInstruction, subject]);
  const filteredInstructions = useMemo(() => {
    const currentPage = getCurrentPage();
    const structure = currentPage?.structure;
    if (structure && about) {
      return instructions.pipe(
        filter<ValidatedInstruction>((inst) => {
          return isAboutMe(structure, inst?.meta?.about, about);
        })
      );
    } else {
      return instructions;
    }
  }, [about, getCurrentPage, instructions]);
  useSubscription(errors, (error) =>
    console.error({tag: 'InstructionError', error})
  );
  return {observable: filteredInstructions, broadcast};
}

export interface ShowInstructions<Schema extends InstructionSchema[]> {
  observable: Observable<DeriveInstruction<Schema>>;
  broadcast: Broadcaster<Schema>;
}

/**
 * When a Module is registered find any internal instructions and add them to
 * the `internalTopics` list.
 */
Registry.on('register', (c) => {
  const instructionSchemas = c.instructions?.anyOf ?? [];
  for (const schema of instructionSchemas) {
    if (FLOW_IGNORE in schema && schema[FLOW_IGNORE] === true) {
      internalTopics.push(schema.properties.topic.const);
    }
  }
});
